import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import icon from '../../static/favicon.ico'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          link={[
            {
              rel: 'shortcut icon',
              type: 'image/png',
              href: `${icon}`,
            },
          ]}
        >
          <meta
            property="og:image"
            content={`https://alexkondov.com${this.props.data.cover.childImageSharp.fluid.src}`}
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            property="og:title"
            content="Alex Kondov's Personal Blog"
          />
          <meta
            property="og:description"
            content="Thoughts on programming, writing and the things in between."
          />
          <meta property="og:url" content="https://alexkondov.com" />
          <meta
            name="twitter:card"
            content="summary_large_image"
          ></meta>
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />
          <title>Alex Kondov - Short Stories</title>
        </Helmet>
        <h2
          className="section-title"
          style={{
            fontFamily: `Merriweather, serif`,
          }}
        >
          Fiction
        </h2>
        <p style={{ marginBottom: 40, textAlign: 'center' }}>
          After dark I trade the keyboard for a pen and write fantasy
          short stories.
        </p>
        <p style={{ marginBottom: 40, textAlign: 'center' }}>
          The lines between fantasy and reality have been blurry to me
          ever since I was little. My imagination gave birth to
          countless heroes, villains and places that didn't belong to
          the world we know.
        </p>
        <p style={{ marginBottom: 40, textAlign: 'center' }}>
          Growing up in Eastern Europe, I wanted to read more stories
          that were inspired by our mythology and folklore, but they
          remained out of the mainstream.
        </p>
        <p style={{ marginBottom: 100, textAlign: 'center' }}>
          So I decided to write them myself.
        </p>

        <a href="https://brokenstatuesbook.com">
          <div className="book">
            <img
              style={{ borderRadius: 3 }}
              src={data.storiesBookCover.childImageSharp.fluid.src}
            />
            <div>
              <h2 style={{ marginTop: 10, fontWeight: 400 }}>
                Broken Statues
              </h2>
              <div className="divider"></div>
              <p>
                My debut collection of fantasy short stories inspired
                by Eastern European mythology.
              </p>
            </div>
          </div>
        </a>

        {/* {posts.map((post) => (
          <Link href={post.node.fields.slug}>
            <div className="book" style={{ marginTop: 100 }}>
              <img
                src={post.node.frontmatter.articleImage}
                style={{
                  borderRadius: '50%',
                  boxShadow: 'none',
                  border: '1px solid black',
                  width: 250,
                  height: 250,
                  padding: 10,
                  background: 'white',
                  objectFit: 'contain',
                }}
              />
              <div>
                <h2 style={{ marginTop: 10, fontWeight: 400 }}>
                  {post.node.frontmatter.title}
                </h2>
                <div className="divider"></div>
                <p>{post.node.frontmatter.description}</p>
              </div>
            </div>
          </Link>
        ))} */}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    cover: file(absolutePath: { regex: "/cover.png/" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    storiesBookCover: file(absolutePath: { regex: "/cover3.png/" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {
          title: {
            in: [
              "The Blue Flower"
              "Monsters Have Good Days"
              "The Golden Apple"
              "One Last March"
              "The Only Question"
            ]
          }
          chapter: { eq: 1 }
        }
      }
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            tags
            chapter
            description
            articleImage
          }
        }
      }
    }
  }
`

function getChapterNameFromNumber(chapterNumber) {
  if (chapterNumber === 0) {
    return 'Interlude'
  }

  if (chapterNumber === null) {
    return 'Unedited'
  }

  return `Chapter ${convertToRomanNumeral(chapterNumber)}`
}

function convertToRomanNumeral(num) {
  const roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  }

  let numeral = ''

  Object.keys(roman).map((key) => {
    const q = Math.floor(num / roman[key])
    num -= q * roman[key]
    numeral += key.repeat(q)
  })

  return numeral
}
